<script setup>
import { ref, reactive, onMounted, watch, nextTick } from 'vue';
import Modal from '../base/Modal.vue';
import Camera from "simple-vue-camera";

let screen = {
    width: window.innerWidth,
    height: window.innerHeight
}

console.log(screen);

const props = defineProps({
    user: {
        type: [Object, null],
        required: true
    },
    tasks: {
        type: [Object, null],
        required: true
    },
    locations: {
        type: [Object, null],
        required: true
    },
    all_tasks: {
        type: [Object, null],
        required: true
    },
    all_locations: {
        type: [Object, null],
        required: true
    },
    info: {
        type: [Object, null],
        required: true
    },
});

const formData = reactive({ id_number: '', about: '', photo_selfie: '', photo_doc: '', photo_avatar: '', tasks: '', locations: '' });
const cameraDocOpened = ref(false); // Is camera opened?
const cameraVideo = ref('');    // Reference for camera
const resolution = ref({width: 1920, height: 1080});    // Camera resolution
const snapshotTaken = ref(false); // Is snapshot taken?
const snapshotImage = ref(''); // Snapshot selfie
const activeCameraMode = ref('selfie'); // Active camera mode
const avatar = ref(''); // Reference for avatar
const error = ref(false);   // Error
const submitted = ref(false);   // Is form submitted?
const selfieImage = ref('');    // Selfie image
const docImage = ref('');   // Doc image
const avatarImage = ref('');   // Avatar image
const locations = ref( Object.keys(props.locations).length > 0 ? props.locations : {} ); // Ref locations
const tasks = ref( Object.keys(props.tasks).length > 0 ? props.tasks : {} ); // Ref tasks
const info = ref( Object.keys(props.info).length > 0 ? props.info : {} ); // Ref info

onMounted( () => {
    // Fill info
    fillTasksFromStorage();
    fillLocationsFromStorage();
    fillInfo();
} );

/** Fill tasks from storage */
const fillTasksFromStorage = () => {
    let tasksStorageSelected = localStorage.getItem('tasks');
    if(tasksStorageSelected != null){
        try {
            tasksStorageSelected = JSON.parse(tasksStorageSelected);
            for (const [key, value] of Object.entries(tasksStorageSelected)) {
                tasks.value[key] = [];
                for ( const [_key, _value] of Object.entries(value) ){
                    let item = props.all_tasks[key].find( record => record.id === _value);
                    if(item != null) tasks.value[key].push(item);
                }
            }

            formData.tasks = JSON.stringify(tasks.value);
            console.log(tasks.value);
        } catch (e){}
    }
}

/** Fill locations from storage */
const fillLocationsFromStorage = () => {
    let locationsStorageSelected = localStorage.getItem('locations');
    if(locationsStorageSelected != null){
        try {
            locationsStorageSelected = JSON.parse(locationsStorageSelected);
            for (const [key, value] of Object.entries(locationsStorageSelected)) {
                locations.value[key] = [];
                for ( const [_key, _value] of Object.entries(value) ){
                    let item = props.all_locations[key].find( record => record.id === _value);
                    if(item != null) locations.value[key].push(item);
                }
            }

            formData.locations = JSON.stringify(locations.value);

            console.log(locations.value);
        } catch (e){}
    }
}

/** Fill info */
const fillInfo = () => {
    let aboutStorage = localStorage.getItem('about');
    let idCodeStorage = localStorage.getItem('id_code');
    let imagesStorage = localStorage.getItem('imagesStorage');

    avatarImage.value = info.value['performer--photo_avatar'] != null ? (info.value['performer--photo_avatar'][0].value ?? '') : '';
    docImage.value = info.value['performer--photo_avatar'] != null ? (info.value['performer--photo_doc'][0].value ?? '') : '';
    selfieImage.value = info.value['performer--photo_selfie'] != null ? (info.value['performer--photo_selfie'][0].value ?? '') : '';

    // About info
    if(aboutStorage != null){
        formData.about = localStorage.getItem('about');
    } else {
        formData.about = info.value['performer--about'] != null ? (info.value['performer--about'][0].value ?? '') : '';
        updateAboutStorage();
    }

    // Id card
    if(idCodeStorage != null){
        formData.id_number = localStorage.getItem('id_code');
    } else {
        formData.id_number = info.value['performer--id_number'] != null ? (info.value['performer--id_number'][0].value ?? '') : '';
        updateIDCardStorage();
    }
}

/** Update about storage */
const updateAboutStorage = () => {
    localStorage.setItem('about', formData.about);
}

/** Update id card storage */
const updateIDCardStorage = () => {
    localStorage.setItem('id_code', formData.id_number);
}


/** Open camera */
const openCamera = (type) => {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        console.log("Let's get this party started")
    }

    nextTick(() => {
        if (screen.width <= 576) {
            document.querySelector('body').style.overflow = 'hidden';
            //document.querySelector('.camera-window').style.height = `${screen.height}px`;
        }
    });

/*     if (screen.width <= 576) {
        resolution.value.width = screen.width - 50;
        resolution.value.height = screen.width * 16 / 9;
    } else {
        resolution.value.width = 1920;
        resolution.value.height = 1080;
    }

    alert(JSON.stringify(resolution.value)); */

    activeCameraMode.value = type;
    cameraDocOpened.value = true;
}

/* Close camera doc */
const closeCameraDoc = () => {
    nextTick(() => {
        document.querySelector('body').style.overflow = '';
    });
    cameraDocOpened.value = false;
    snapshotImage.value = '';
    snapshotTaken.value = false;

    if(activeCameraMode.value == 'selfie'){
        selfieImage.value = URL.createObjectURL(formData.photo_selfie);
    } else {
        docImage.value = URL.createObjectURL(formData.photo_doc);
    }
}

/* window.addEventListener("resize", () => {
    [screen.width, screen.height] = [window.innerWidth, window.innerHeight];
    nextTick(() => {
        if (screen.width <= 576) document.querySelector('.camera-window').style.height = `${screen.height}px`;
    });
}); */


/** Snapshot */
const snapshot = async () => {
    //const blob = await cameraVideo.value?.snapshot();
    let blob = null;
    if(screen.width <= 576){
        let height = document.querySelector('.camera-window').offsetHeight;
        blob = await cameraVideo.value?.snapshot({ width: screen.width, height: height }, "image/png",
        1);
    } else {
        blob = await cameraVideo.value?.snapshot();
    }

   /*  blob = await cameraVideo.value?.snapshot(); */

    // To show the screenshot with an image tag, create a url
    const url = URL.createObjectURL(blob);

    console.log(url);

    snapshotTaken.value = true;
    snapshotImage.value = url;

    formData[`photo_${activeCameraMode.value}`] = blob;
}

/** Reset photo */
const resetPhoto = () => {
    snapshotTaken.value = false;
    formData[`photo_${activeCameraMode.value}`] = '';
}

/** Submit form */
const submit = () => {
    if (submitted.value) return;

    submitted.value = true;
    console.log(formData);

    let validated = validateForm();
    if (!validated) {
        submitted.value = false;
        return;
    }

    let form = new FormData();
    form.append('about', formData.about);
    form.append('id_number', formData.id_number);
    form.append('photo_avatar', formData.photo_avatar);
    form.append('photo_selfie', formData.photo_selfie);
    form.append('photo_doc', formData.photo_doc);

    form.append('tasks', formData.tasks);
    form.append('locations', formData.locations);

    axios.post('/performer/questionary/send', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
        .then(function (response) {
            console.log(response.data);
            submitted.value = false;
            /* If Error */
            if (response.data.error != null) {
                error.value = response.data.error;
                return;
            }
            /* If Success */
            location.href = response.data.success;
        }).catch(function (error) {
            submitted.value = false;
            console.log(error);
        });
}

/** Choose avatar */
const selectAvatar = (event) => {
    formData.photo_avatar = event.target.files[0];
    avatarImage.value = URL.createObjectURL(formData.photo_avatar);
}

/** Validate form */
const validateForm = () => {
    error.value = false;

    if (formData.tasks.length == 0) {
        error.value = 'You must choose at least 1 task';
        return false;
    }

    if (formData.locations.length == 0) {
        error.value = 'You must choose at least 1 location';
        return false;
    }

    let aboutLength = formData.about.trim().length;
    if (aboutLength < 1) {
        error.value = 'The field `about` must be filled';
        return false;
    }

    if (aboutLength > 250) {
        error.value = 'The field `about` must be filled';
        return false;
    }

    let idNumberLength = formData.id_number.trim().length;
    let permittedNumberLength = [9, 12];

    if (!permittedNumberLength.includes(idNumberLength)) {
        error.value = 'Length of the field `ID Card` must be 9 or 12 numbers';
        return false;
    }

    if (formData.photo_doc == '' && docImage.value == '') {
        error.value = "You must provide your document's photo";
        return false;
    }

    if (formData.photo_selfie == '' && selfieImage.value == '') {
        error.value = 'You must provide your selfie';
        return false;
    }

    if (formData.photo_avatar == '' && avatarImage.value == '') {
        error.value = 'You must upload avatar';
        return false;
    }

    return true;
}
</script>

<template>
    <div class="order-container container-mob-padded">
        <h2 class="main-title">
            Анкета виконавця
        </h2>

        <ul class="auth__ul">
            <li class="auth__li">
                <input class="auth__input" placeholder="Ім’я" :value="user.full_name" readonly>
            </li>

            <li class="auth__li">
                <input class="auth__input" placeholder="Номер телефону" :value="user.phone_number" readonly>
            </li>

            <li class="auth__li">
                <input type="email" class="auth__input" placeholder="Email" :value="user.email" readonly>
            </li>

            <!-- <div class="auth__li-choose">
                <li class="auth__li">
                  <input class="auth__input" placeholder="Номер телефону" id="name">

                  <span class="auth__li-check">Основний</span>
                </li>
                <label><input class="order__check" type="radio" name="x" value="00" checked>
                  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="72" height="72" rx="19.5" transform="matrix(-1 0 0 1 72 0)" />
                    <path d="M24 37L33 47L50 26" stroke-width="1.95" stroke-linecap="square" stroke-linejoin="round" />
                  </svg>
                </label>
              </div> -->

            <!-- <div class="auth__li-choose">
                <li class="auth__li">
                  <input class="auth__input" placeholder="Номер телефону" id="name">
                </li>
                <label><input class="order__check" type="radio" name="x" value="01">
                  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="72" height="72" rx="19.5" transform="matrix(-1 0 0 1 72 0)" />
                    <path d="M24 37L33 47L50 26" stroke-width="1.95" stroke-linecap="square" stroke-linejoin="round" />
                  </svg>
                </label>
              </div> -->
            <!-- <span class="auth__wrong">Додати телефон</span>

              <div class="auth__li-choose">
                <li class="auth__li">
                  <input class="auth__input" placeholder="Email" id="name">

                  <span class="auth__li-check">Основний</span>
                </li>
                <label><input class="order__check" type="radio" name="x1" value="02" checked>
                  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="72" height="72" rx="19.5" transform="matrix(-1 0 0 1 72 0)" />
                    <path d="M24 37L33 47L50 26" stroke-width="1.95" stroke-linecap="square" stroke-linejoin="round" />
                  </svg>
                </label>
              </div> -->

            <!-- <div class="auth__li-choose">
                <li class="auth__li">
                  <input class="auth__input" placeholder="Email" id="name">

                </li>
                <label><input class="order__check" type="radio" name="x1" value="03">
                  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="72" height="72" rx="19.5" transform="matrix(-1 0 0 1 72 0)" />
                    <path d="M24 37L33 47L50 26" stroke-width="1.95" stroke-linecap="square" stroke-linejoin="round" />
                  </svg>
                </label>
              </div>
              <span class="auth__wrong">Додати email</span> -->

            <label>ID Card</label>
            <li class="auth__li">
                <input class="auth__input" type="text" minlength="9" maxlength="12"
                    oninput="this.value=this.value.replace(/[^\d]/,'')" placeholder="ID Card" v-model="formData.id_number" @change="updateIDCardStorage">
            </li>

            <div class="auth__li-together">
                <label>Коротка інформація про себе</label>
                <span class="order__desc-form order__desc-form-without">Буде видна замовникам</span>
                <textarea class="auth__textarea" maxlength="200" placeholder="Коротко про свої навички"
                    v-model="formData.about" @change="updateAboutStorage"></textarea>
            </div>
        </ul>
    </div>

    <hr class="order-hr">

    <div class="order-container">
        <h2 class="main-title">
            Вибір послуг які готові надати
        </h2>

        <p class="order__desc-form">Ви будете отримувати повідомлення про завдання тільки із обраних категорій
            послуг
        </p>

        <ul class="order__locations">
            <li v-for="(value, key) in tasks">
                <img :src="value[0].icon" :alt="key">

                <div class="">
                    <h3>{{ key }}</h3>
                    <p v-for="(_value, _key) in value">{{ _value.subcategory_1 }}</p>
                </div>
            </li>
        </ul>

        <a href="/services/choose" class="auth__tg auth__tg-max auth-tg-border">Додати нові послуги</a>
    </div>

    <hr class="order-hr">

    <div class="order-container">
        <h2 class="main-title">
            Вибір локації де готові надавати послуги
        </h2>

        <ul class="order__locations">
            <li v-for="(value, key) in locations">
                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 24C10 24 20 17.2 20 10C20 7.45392 18.8754 4.80035 17 3C15.1246 1.19965 12.6522 0 10 0C7.34784 0 4.87536 1.19965 3 3C1.12464 4.80035 0 7.45392 0 10C0 17.2 10 24 10 24Z"
                        fill="#5F5F5F" />
                    <path
                        d="M10 13C12.2091 13 14 11.933 14 10C14 8.067 12.2091 6 10 6C7.79086 6 6 8.067 6 10C6 11.933 7.79086 13 10 13Z"
                        fill="white" />
                </svg>

                <div class="">
                    <h3>{{ key }}</h3>
                    <p v-for="(_value, _key) in value">{{ _value.district }}</p>
                </div>
            </li>
        </ul>

        <a href="/locations/choose" class="auth__tg auth__tg-max auth-tg-border">Змінити локації</a>
    </div>

    <hr class="order-hr">

    <div class="order-container">
        <h2 class="main-title">
            Додати документи
        </h2>

        <div class="order-container-inside order-container-inside-yellow">
            <span>Цей етап необхідний для того, щоб замовники вам довіряли. Вся інформація в цих блоках
                конфіденційна.</span>
        </div>

        <ul class="order__adds">
            <li>
                <label>Селфі</label>
                <button v-if="selfieImage == ''" class="btn btn-orange-outline" type="button" @click="openCamera('selfie')">Додати селфі</button>
                <!-- <a href="#" class="auth__tg auth__tg-max auth-tg-border auth-tg-border-yellow" @click="cameraDocOpened = true">Додати
                    селфі</a> -->
                <img v-else :src="selfieImage" alt="" @click="openCamera('selfie')" class="questionary-image-preview">
            </li>

            <li>
                <label>Лицьова сторінка паспорта</label>
                <button v-if="docImage == ''" class="btn btn-orange-outline" type="button" @click="openCamera('doc')">Додати фото</button>
                <img v-else :src="docImage" alt="" @click="openCamera('doc')" class="questionary-image-preview">
                <!--                 <a href="/" class="auth__tg auth__tg-max auth-tg-border auth-tg-border-yellow">Додати
                    фото</a> -->
            </li>

            <li>
                <label>Додати аватар (повинно бути своє лице)</label>
                <button v-if="avatarImage == ''" class="btn btn-orange-outline" type="button" accept="image/*"
                    @click="$refs.avatar.click()">Прикріпити файл</button>
                <img v-else :src="avatarImage" alt="" @click="$refs.avatar.click()" class="questionary-image-preview">
                <!--                 <a href="/" class="auth__tg auth__tg-max auth-tg-border auth-tg-border-yellow">Прикріпити
                    файл</a> -->
            </li>
        </ul>

        <input type="file" ref="avatar" @change="selectAvatar" style="visibility: hidden; height: 0; width: 0;">

        <button type="button" class="auth__tg auth__tg-max" id="send-performer-questionary" @click="submit"
            :disabled="submitted">Відправити на розгляд</button>

        <transition name="fade">
            <p class="error text-center" style="margin-top: 10px;" v-if="error">{{ error }}</p>
        </transition>
    </div>

    <!-- popup "capture document" -->
    <Modal id="popup-camera-document" v-if="cameraDocOpened" @onClose="closeCameraDoc" :extra_classes="['camera-window']"
        :close_button="screen.width < 756 ? false : true" :is_big="true">
        <template #content>
            <button class="btn back" @click="closeCameraDoc" v-if="screen.width < 756"></button>
            <transition name="fade" mode="out-in">
                <div class="camera-content" v-if="!snapshotTaken">
<!--                    <camera ref="cameraVideo" :playsinline="true" :resolution="resolution" :facingMode="activeCameraMode == 'selfie' ? 'user' : 'environment'" autoplay>
                            <div class="camera-buttons">
                                <button type="button" class="btn btn-white-green take-photo" @click="snapshot">Зробити фото</button>
                            </div>
                        </camera> -->
                    <camera ref="cameraVideo"
                        :facingMode="screen.width <= 991 ? (activeCameraMode == 'selfie' ? 'user' : 'environment') : 'user'" autoplay>
                        <div class="camera-buttons">
                            <button type="button" class="btn btn-white-green take-photo" @click="snapshot">Зробити
                                фото</button>
                        </div>
                    </camera>
                </div>
                <div class="camera-result" v-else>
                    <!-- <img v-if="snapshotImage != ''" :src="snapshotImage" alt="" :style="[{'width': screen.width < 756 ? `${screen.width}px` : '358px', height: screen.width < 756 ? `${screen.height}px` : '599px' }]">
                        <div class="camera-buttons">
                            <button type="button" class="btn btn-green" @click="closeCameraDoc">Підтвердити</button>
                            <button type="button" class="btn btn-white-orange" @click="resetPhoto">Переробити</button>
                        </div> -->
                    <img v-if="snapshotImage != ''" :src="snapshotImage" alt="" class="camera-image-snapshot">
                    <div class="camera-buttons">
                        <button type="button" class="btn btn-green" @click="closeCameraDoc">Підтвердити</button>
                        <button type="button" class="btn btn-white-orange" @click="resetPhoto">Переробити</button>
                    </div>
                </div>
            </transition>

    </template>
</Modal>
<!-- popup "capture document" --></template>
