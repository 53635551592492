<script setup>
import { ref, reactive, onMounted } from 'vue';

const props = defineProps(['id', 'is_big', 'extra_classes', 'close_button', 'opened']);

const opened = ref(props.opened ?? true);   // Is Modal opened?

const emit = defineEmits(['onClose', 'onOpen']);

const closeModal = () => {
    opened.value = false;
    emit('onClose');
}

const openModal = () => {
    opened.value = true;
    emit('onOpen');
}

// Methods to be allowed to execute from parent
defineExpose({ openModal });

</script>

<template>
    <div class="popup" :class="[{'popup_opened': opened}, {'popup_big': is_big}, extra_classes]" :id="id">
        <div class="popup__group">
            <button class="button popup__close" type="button" @click="closeModal" v-if="close_button"></button>
            <slot name="content"></slot>
        </div>
    </div>
</template>
