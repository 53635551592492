import.meta.glob([
    '../images/**',
]);

import './bootstrap';

/* Include Swiper */
import Swiper from 'swiper';
window.Swiper = Swiper;

/* Include layout's script */
import './index';

////////////////////////////

// VUE 3 //
import { createApp } from 'vue';
import { createPinia } from 'pinia';
const pinia = createPinia();
const app = createApp({});
app.use(pinia);

import { useUserStore } from './stores/UserStore.js';
window.userStore = useUserStore();

/* Auth */
import Auth from './components/auth/Auth.vue';
import AuthQuestionary from './components/auth/AuthQuestionary.vue';

/* Order app */
import OrderApp from './components/order/OrderApp.vue';
import OrderAppEdit from './components/order/OrderAppEdit.vue';

/* Cabinet: Customer */
import CustomerProposals from './components/customer/CustomerProposals.vue';

/* Cabinet: Performer */
import PerformerQuestionary from './components/performer/Questionary.vue';
import PerformerServices from './components/performer/Services.vue';
import PerformerLocations from './components/performer/Locations.vue';
import PerformerOrdersNew from './components/performer/OrdersNew.vue';

/* Adding components */
if( document.querySelector('auth') ) app.component('auth', Auth);
if( document.querySelector('auth-questionary') ) app.component('auth-questionary', AuthQuestionary);
if( document.querySelector('order-app') ) app.component('order-app', OrderApp);
if( document.querySelector('order-edit') ) app.component('order-edit', OrderAppEdit);
if( document.querySelector('customer-proposals') ) app.component('customer-proposals', CustomerProposals);
if( document.querySelector('performer-questionary') ) app.component('performer-questionary', PerformerQuestionary);
if( document.querySelector('services-choose') ) app.component('services-choose', PerformerServices);
if( document.querySelector('locations-choose') ) app.component('locations-choose', PerformerLocations);
if( document.querySelector('perfomer-orders-new') ) app.component('perfomer-orders-new', PerformerOrdersNew);

// Mount app
if( document.querySelector('#app') )  app.mount('#app');

