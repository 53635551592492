<script setup>
    import { ref, reactive, onMounted, nextTick } from 'vue';

    const props = defineProps({
        tg_bot_url: String,
        type: String,
        from_another_page: {
            type: Boolean,
            default: false
        },
        fullwidth: {
            type: Boolean,
            default: false
        },
    });

    /** Emits */
    const emit = defineEmits(['onAuthPassed']);

    const formShown = ref(false);   // Is block shown?
    const tgButtonClicked = ref(false); // Is TG Bot button clicked?
    const phoneError = reactive({
        status: false,
        message: ''
    });  // Phone error
    const codeError = ref(false);  // Code error
    const responseError = ref('');   // Response Error Data
    const formData = reactive({phone: '', code: ''}); // Form data
    const codeParts = reactive({1: '', 2: '', 3: '', 4: ''});   // Code parts values

    const PHONE_OPERATORS = ["032", "033", "034", "035", "036", "037", "038", "039", "050", "052", "056", "058", "059", "063",  "067", "066", "068", "069", "070", "076", "077", "078", "079", "081", "082", "083", "084", "085", "086", "087", "088", "089", "090", "091", "092", "093", "094", "095", "096", "097", "098", "099"];

    onMounted( () => {
        formShown.value = true;
    } );

    /** Check phone */
    const checkPhone = () => {
        if(formData.phone == '' || formData.phone.length < 10){
            phoneError.status = true;
            phoneError.message = 'The length of the field "Phone number" must not be less than 10 characters';
            return;
        }

        if( formData.phone.startsWith(0) ){
            let operatorCode = formData.phone.substring(0, 3);
            if( !PHONE_OPERATORS.includes(operatorCode) ) {
                phoneError.status = true;
                phoneError.message = 'Invalid operator code. Valid values: 32,33,34,35,36,37,38,39,50,52,56,58,59,63,66,67,68,69,70,76,77,78,79,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99';
                return;
            }
        } else {
            phoneError.status = true;
            phoneError.message = 'Invalid operator code. Valid values: 32,33,34,35,36,37,38,39,52,56,58,59,63,66,67,68,69,70,76,77,78,79,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99';
            return;
        }

        phoneError.status = false;
    }

    /** Check code */
    const checkCode = () => {
        formData.code = '';
        for (const [key, value] of Object.entries(codeParts)) {
            formData.code += value;
        }

        codeError.value = (formData.code == '' || formData.code.length < 4);
    }

    /** Check code partly */
    const checkCodePartly = (part) => {
        codeParts[part] = codeParts[part].replace(/[^0-9]/g,'');

        let nextPart;
        if(codeParts[part] == ''){
            nextPart = --part;
        } else {
            nextPart = ++part;
        }

        if(nextPart > 4) nextPart = 4;
        if(nextPart < 1) nextPart = 1;

        nextTick( () => {
            document.querySelector(`.otp-code input[data-part="${nextPart}"]`).focus();
        } );
    }

    /* Open TG Bot */
    const openTgBot = () => {
        checkPhone();
        if(phoneError.status) return;

        tgButtonClicked.value = true;

        window.open(props.tg_bot_url, '_blank').focus();

        sendOTP();
    }

    /** Accept code */
    const acceptCode = () => {
        checkCode();
        if(codeError.value) return;

        axios.post('/telegram/otp/accept', formData)
        .then(function (response) {
            console.log(response.data);
            // If success response
            if( response.data.success != null ){
                /* If the request was sent from another page */
                if( !props.from_another_page ) {
                    window.open( response.data.need_questionary ? "/questionary" : '/cabinet', "_self");
                } else {
                    // Call event from parent
                    response.data.code = formData.code;
                    emit('onAuthPassed', response.data);
                }
            } else {
                responseError.value = response.data;
            }
        }).catch(function (error) {
            console.log(error);
            responseError.value = "Server error code: #" +error.response.status;
        });
    }

    /** Send OTP */
    const sendOTP = () => {
        axios.post(`/telegram/otp/generate`, {
            phone: formData.phone
        })
        .then(function (response) {
            console.log(response.data);
            // If error
            if(response.data.error != null){
                responseError.value = response.data;
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
</script>

<template>
    <transition name="fade" mode="out-in">
        <div class="main-container auth" v-if="formShown && !tgButtonClicked">
            <h2 class="main-title main-title-margin-bottom text-center" v-if="!from_another_page"><slot name="title">Реєстрація в сервісі за допомогою Telegram</slot></h2>

            <div class="auth-container" :style="[fullwidth ? {'max-width': '100%'} : '']">
                <div class="input-group">
                    <label>Введіть свій номер телефону</label>
                    <input type="text" maxlength="10" class="input-clear" placeholder="Номер телефону" id="phone" v-model="formData.phone">
                    <transition name="fade">
                        <p class="error" v-if="phoneError.status">{{ phoneError.message }}</p>
                    </transition>
                </div>

                <div class="input-group">
                    <label>Відкрийте чат "Bloomer" в Telegram та слідуйте інструкціям щоб отримати ОТП-код</label>
                    <button type="button" class="btn" :class="type == 'register' ? 'btn-orange-outline' : 'btn-green-outline'" @click="openTgBot">Відкрити</button>
                </div>
            </div>
        </div>

        <div class="main-container auth" v-else-if="formShown && tgButtonClicked">
            <div class="auth-container">
                <div class="input-group">
                    <label>Введіть код підтвердження, який ви отримали в Telegram</label>

                    <div class="otp-code">
                        <input type="text" class="input-clear" placeholder="-" maxlength="1" @input="checkCodePartly(1)" v-model="codeParts[1]" data-part="1">
                        <input type="text" class="input-clear" placeholder="-" maxlength="1" @input="checkCodePartly(2)" v-model="codeParts[2]" data-part="2">
                        <input type="text" class="input-clear" placeholder="-" maxlength="1" @input="checkCodePartly(3)" v-model="codeParts[3]" data-part="3">
                        <input type="text" class="input-clear" placeholder="-" maxlength="1" @input="checkCodePartly(4)" v-model="codeParts[4]" data-part="4">
                    </div>

                    <transition name="fade">
                        <p class="error" v-if="codeError">Incorrect otp-code entered</p>
                    </transition>
                    <button type="button" v-if="type == 'register'" class="btn" :class="[codeParts[4] != '' ? 'btn-orange' : 'btn-orange-outline']" @click="acceptCode">Підтвердити</button>
                    <button type="button" v-else class="btn" :class="[codeParts[4] != '' ? 'btn-green' : 'btn-green-outline']" @click="acceptCode">Підтвердити</button>

                    <transition name="fade">
                        <p class="error" v-if="responseError.error != null">{{ responseError.error }}</p>
                    </transition>

                    <a href="#" type="button" id="send-code-twice" :class="[type == 'register' ? 'text-orange' : 'text-green']" @click="sendOTP">Відправити повторно</a>
                </div>
            </div>
        </div>

    </transition>

</template>
