<script setup>
    import { ref, reactive, onMounted, watch } from 'vue';
    import Auth from '../auth/Auth.vue';

    const userStore = window.userStore;

    const authShown = ref(false);   // Is auth form shown?
    const tgUrl = ref(false);   // TG Url
    const readonly = reactive({full_name: userStore.user != null && ( userStore.user.full_name != null && userStore.user.full_name != ''), email: userStore.user != null && ( userStore.user.email != null && userStore.user.email != '')});    // Are fields readonly?
    //const userData = ref(props.user);

    /* On Auth passed event */
    const onAuthPassed = (data) => {
        userStore.user = data.user;
        userStore.user.full_name = data.user.full_name ?? '';
        userStore.user.email = data.user.email ?? '';

        if(userStore.user.email != '') readonly.email = true;
        if(userStore.user.full_name != '') readonly.full_name = true;

        userStore.user.phone_number = data.user.phone_number ?? '';
        userStore.user.code = data.code ?? '';
    }

    watch(authShown, (newVal, oldVal) => {
        axios.post('/telegram/bot_url', {})
        .then(function (response) {
            tgUrl.value = response.data;
        }).catch(function (error) {
            console.log(error);
        });
    });

</script>

<template>
    <transition name="fade" mode="out-in">
        <div class="child-auth" v-if="userStore.user == null && !authShown">
            <div class="auth-container-choose">
                <span>Вже зареєстрований?</span>
                <button type="button" class="auth__tg auth__tg-max auth-tg-border" @click="authShown = 'auth'">Увійти</button>
            </div>

            <div class="auth-container-choose">
                <span>Не зареєстровані?</span>
                <button type="button" class="auth__tg auth__tg-max auth-tg-border auth-tg-border-yellow" @click="authShown = 'register'">Зареєструватись</button>
            </div>
        </div>

        <Auth v-else-if="userStore.user == null && (authShown == 'auth' && tgUrl)" :type="'auth'" :from_another_page="true" :fullwidth="true" :tg_bot_url="tgUrl" @on-auth-passed="onAuthPassed"></Auth>
        <Auth v-else-if="userStore.user == null && (authShown == 'register' && tgUrl)" :type="'register'" :from_another_page="true" :fullwidth="true" :tg_bot_url="tgUrl" @on-auth-passed="onAuthPassed"></Auth>

        <ul class="auth__ul" v-else-if="userStore.user != null">
            <li class="auth__li">
                <input class="auth__input" type="text" placeholder="ПІБ" id="full_name" name="full_name" :readonly="readonly.full_name" required v-model="userStore.user.full_name">
            </li>

            <li class="auth__li">
                <input class="auth__input" type="text" placeholder="Номер телефону" id="phone" name="phone_number" readonly required v-model="userStore.user.phone_number">
            </li>

            <li class="auth__li">
                <input class="auth__input" type="email" placeholder="Email" id="email" name="email" :readonly="readonly.email" required v-model="userStore.user.email">
            </li>
        </ul>
    </transition>

</template>
