// header open menu START
const proficeIcon = document.querySelector(".header-js-menu");
const menu = document.querySelector(".dropdown-content");
const shadowContent = document.querySelector(".content-shadow");
const earnButton = document.querySelectorAll('.button-earn');
const openPopups = document.querySelectorAll('.open-popup');

proficeIcon?.addEventListener("click", (event) => {
    //event.preventDefault();
    if(menu && shadowContent){
        menu.classList.toggle("dropdown-content-active");
        shadowContent.classList.toggle("content-shadow-active");
    }
});

// header open menu END

// class for open button
const openMenuContactButtonOne = document.querySelectorAll(".open-menu-contact-one")
const openContactButtonOne = document.querySelectorAll(".open-popup-contact-one")
const openContactButtonTwo = document.querySelectorAll(".open-popup-contact-two")
const openContactButtonThree = document.querySelectorAll(".open-popup-contact-three")
const openContactButtonFour = document.querySelectorAll(".open-popup-contact-four")
const openContactButtonFive = document.querySelectorAll(".open-popup-contact-five")
const openContactButtonSix = document.querySelectorAll(".open-popup-contact-six")
const openContactButtonSeven = document.querySelectorAll(".open-popup-contact-seven")

// Popup
const popups = document.querySelectorAll(".popup")
const menuContactOne = document.querySelector(".menu-contact-one")
const popupContactOne = document.querySelector(".popup-contact-one")
const popupContactTwo = document.querySelector(".popup-contact-two")
const popupContactThree = document.querySelector(".popup-contact-three")
const popupContactFour = document.querySelector(".popup-contact-four")
const popupContactFive = document.querySelector(".popup-contact-five")
const popupContactSix = document.querySelector(".popup-contact-six")
const popupContactSeven = document.querySelector(".popup-contact-seven")



// Functions
function handleOpenPopup(popup) {
    popup.classList.add("popup_opened");
    document.addEventListener('keydown', handleKeyEscape);
}

function handleClosePopup(popup) {
    popup.classList.remove("popup_opened");
    document.removeEventListener('keydown', handleKeyEscape);
}

function handleKeyEscape(evt) {
    if (evt.key === 'Escape') {
        const openedPopup = document.querySelector('.popup_opened');
        handleClosePopup(openedPopup);
    }
}


// close popup
popups.forEach((popup) => {
    popup.addEventListener("mousedown", (evt) => {
        if (evt.target.classList.contains("popup_opened")) {
            handleClosePopup(popup);
        };
        if (evt.target.classList.contains("popup__close")) {
            handleClosePopup(popup);
        };
        if (evt.target.classList.contains("menu_close")) {
            handleClosePopup(popup);
        };
    });
});

openMenuContactButtonOne?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(menuContactOne);
    }));

openContactButtonOne?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(popupContactOne);
    }));

openContactButtonTwo?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(popupContactTwo);
    }));

openContactButtonThree?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(popupContactThree);
    }));

openContactButtonFour?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(popupContactFour);
    }));

openContactButtonFive?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(popupContactFive);
    }));

openContactButtonSix?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(popupContactSix);
    }));

openContactButtonSeven?.forEach(element =>
    element.addEventListener("click", function () {
        handleOpenPopup(popupContactSeven);
    }));



// more text START
let textMore = document.querySelectorAll(".order__author-desc");
let buttonTextlMore = document.querySelectorAll(".text-button");
let buttonTextMinus = document.querySelector(".text-minus");
let buttonTextPlus = document.querySelector(".text-more");


function moreInfoText() {
    buttonTextPlus.style.display = getComputedStyle(buttonTextPlus).display == 'block' ? 'none' : 'block';
    buttonTextMinus.style.display = getComputedStyle(buttonTextMinus).display == 'block' ? 'none' : 'block';

    textMore.forEach((listItem, i) => {
        listItem.classList.toggle("text-active");
    });
};

buttonTextlMore?.forEach(function (likeIcon) {
    likeIcon.addEventListener("click", moreInfoText);
});
// more text END


// On Earn Button clicked
earnButton?.forEach(function (button) {
    button.addEventListener("click", () => {
        proficeIcon.click();
    });
});


// Return to previous coords on page
window.addEventListener('popstate', () => {
    if (localStorage.getItem("scroll_pos") != null) {
        document.documentElement.scrollTop = document.body.scrollTop = localStorage.getItem("scroll_pos");
        localStorage.removeItem('scroll_pos');
    }
});

window.addEventListener('beforeunload', function (e) {
    localStorage.setItem("scroll_pos", document.documentElement.scrollTop || document.body.scrollTop );
});

// Open popup
openPopups?.forEach(element =>
    element.addEventListener("click", function () {
        let popup = this.getAttribute('data-popup');
        console.log(popup);
        handleOpenPopup( document.getElementById(popup) );
    })
);

/** Show errors */
window.showError = (errors, popup = 'popup-message') => {
    let messageSelector = document.querySelector('#message');
    messageSelector.innerHTML = '';

    if (Array.isArray(errors)) {
        errors.forEach( (value, index) => {
            messageSelector.innerHTML += `<p class="error text-center">${value}</p>`;
        } );
    } else {
        messageSelector.innerHTML = `<p class="error text-center">${errors}</p>`;
    }

    handleOpenPopup( document.getElementById(popup) );
}

/* Show messages */
window.showMessage = (message, popup = 'popup-message') => {
    let messageSelector = document.querySelector('#message');
    messageSelector.innerHTML = '';

    messageSelector.innerHTML = `<p class="text-green text-center">${message}</p>`;

    handleOpenPopup( document.getElementById(popup) );
}
