import { defineStore } from 'pinia';

export const useUserStore = defineStore('UserStore', {
    state: () => ({
        user: null,
        performer: null
    }),
    actions: {
        // Set user
        setUser(user){
            this.user = user;
        },
        // Set performer
        setPerformer(performer){
            this.performer = performer;
        }
    },
});
