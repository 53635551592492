<script setup>
    import { ref, reactive, onMounted, nextTick } from 'vue';
    import { HELPERS } from '../../helpers';
    import OrderAppAuth from './OrderAppAuth.vue';

    const userStore = window.userStore;

    const props = defineProps({
        tasks: {
            type: [Object, null],
            required: true
        },
        locations: {
            type: [Object, null],
            required: true
        },
        order: {
            type: [Object, null],
            required: true
        },
        user: {
            type: [Object, null]
        }
    });

    userStore.setUser(props.user);

    const date = new Date();

    let tomorrow = (new Date()).setDate(date.getDate() + 1);
    tomorrow = new Date(tomorrow);

    let days2after = (new Date()).setDate(date.getDate() + 2);
    days2after = new Date(days2after);

    const subcategories = ref([]);  // Subcategories
    const districts = ref([]);  // Districts
    const formData = reactive({order_id: props.order.order_id, category: props.order.task.category ?? '', subcategory: props.order.task.id ?? '', city: '', district: '', location_street: '', description_short: '', description_full: '', due_date: tomorrow, due_time: '8:00-12:00', price_start: '',  rules_accepted: true});  // Form data
    const responseError = ref({ error: null });   // Response Error Data

    onMounted( () => {
        setSubCategories();

        for (const [key, value] of Object.entries(props.order.sorted_attributes)) {
            let attrKey = key.replace('order--', '');
            if( formData.hasOwnProperty(attrKey) ){
                formData[attrKey] = value[0].value;
            }
        }

        formData.city = props.order.location.city;
        setLocation();
        formData.district = props.order.location.id;

        nextTick( () => {
            document.querySelector(`label[data-due_time="${formData.due_time}"]`).click();
            document.querySelector(`label[data-due_date="${new Date(formData.due_date).getDate()}"]`).click();
        } );
    } );

    /* Set subcategories by category */
    const setSubCategories = () => {
        subcategories.value = [];
        if( formData.category == '' ) return;

        for (const [key, value] of Object.entries( props.tasks[formData.category] )) {
            subcategories.value.push(value);
        }
    }

    /* Set location */
    const setLocation = () => {
        districts.value = [];
        if( formData.city == '' ) return;

        for (const [key, value] of Object.entries( props.locations[formData.city] )) {
            districts.value.push(value);
        }
    }

    /** Send order */
    const editOrder = () => {
        validateOrder();
        if( responseError.value.error != null ) return; // If errors found after validation

        axios.post('/app/order/update', formData)
        .then(function (response) {
            console.log(response.data);
            if(response.data.url != null) location.href = response.data.url;
        }).catch(function (error) {
            console.log(error);
        });
    }

    /** Validate order */
    const validateOrder = () => {
        responseError.value.error = null;

        if(userStore.user == null) {
            responseError.value.error = 'You need to be authed to perform action';
            return;
        }

        // Rules accepted?
        if(!formData.rules_accepted){
            responseError.value.error = 'You need to be agreed with rules';
            return;
        }

        // Price
        if(formData.price_start == '') {
            responseError.value.error = 'You must set the price';
            return;
        }

        if(formData.price_start <= 0) {
            responseError.value.error = 'Price must be greater than 0';
            return;
        }

        // Work time
        let availableWorkTimes = ['any', '8:00-12:00', '12:00-16:00', '16:00-20:00'];
        if( !availableWorkTimes.includes(formData.due_time) ) {
            responseError.value.error = 'Bad time selected';
            return;
        }

        // Day
        if(formData.due_date == ''){
            responseError.value.error = 'Bad date selected';
            return;
        }

        // Street
        if(formData.location_street == ''){
            responseError.value.error = 'You must set the street';
            return;
        }

        if(formData.location_street.length > 25){
            responseError.value.error = 'The street attribute must be lower than 25 symbols';
            return;
        }

        // District
        if(formData.district == ''){
            responseError.value.error = 'You must choose the district';
            return;
        }

        // City
        if(formData.city == ''){
            responseError.value.error = 'You must choose the city';
            return;
        }

        // Description full
        if(formData.description_full == ''){
            responseError.value.error = 'You must set the full description';
            return;
        }

        if(formData.description_full.length > 250){
            responseError.value.error = 'The Description Full attribute must be lower than 250 symbols';
            return;
        }

        // Description short
        if(formData.description_short == ''){
            responseError.value.error = 'You must set the short description';
            return;
        }

        if(formData.description_short.length > 50){
            responseError.value.error = 'The Description Short attribute must be lower than 50 symbols';
            return;
        }

        // Subcategory
        if(formData.subcategory == ''){
            responseError.value.error = 'You need to set subcategory';
            return;
        }

        // Category
        if(formData.category == ''){
            responseError.value.error = 'You need to set category';
            return;
        }
    }

</script>

<template>
    <form class="main-container auth order" @submit.prevent="editOrder">
        <div class="order-container order-container_top-padding-mob">
            <h2 class="main-title">
                Оберіть вид послуги
            </h2>

            <div class="auth-container-choose">
                <span>Категорія</span>
                <select class="order__input" name="input_custom" @change="setSubCategories" v-model="formData.category">
                    <option value="" disabled selected>Категорія</option>
                    <option :value="key" v-for="(value, key) in tasks">{{ key }}</option>
                </select>
            </div>

            <div class="auth-container-choose">
                <span>Підкатегорія</span>
                <select class="order__input" name="input_custom" v-model="formData.subcategory">
                    <option value="" disabled selected>Виберіть підкатегорію</option>
                    <option :value="value.id" v-for="(value, key) in subcategories">{{ value.subcategory_1 }}</option>
                </select>
            </div>
        </div>

        <hr class="order-hr">

        <div class="order-container">
            <h2 class="main-title">
                Опишіть замовлення
            </h2>

            <div class="auth-container-choose">
                <span>Що потрібно зоробити (коротко)?</span>
                <div class="order__input-price">
                    <input type="text" maxlength="50" placeholder="Монтаж каналізації" v-model="formData.description_short">
                </div>
            </div>

            <div class="auth-container-choose">
                <span>Детально опишіть замовлення</span>
                <textarea class="auth__textarea" maxlength="250" placeholder="Чим детальніше – тим краще" id="desc" v-model="formData.description_full"></textarea>
            </div>
        </div>

        <hr class="order-hr">

        <div class="order-container">
            <h2 class="main-title">
                Адреса виконання замовлення
            </h2>

            <div class="auth-container-choose">
                <span>Місто</span>
                <select class="order__input" name="input_custom" @change="setLocation" v-model="formData.city">
                    <option value="" disabled selected>Виберіть місто</option>
                    <option :value="key" v-for="(value, key) in locations">{{ key }}</option>
                </select>
            </div>

            <div class="auth-container-choose">
                <span>Район</span>
                <select class="order__input" name="input_custom" v-model="formData.district">
                    <option value="" disabled selected>Виберіть район</option>
                    <option :value="value.id" v-for="(value, key) in districts">{{ value.district }}</option>
                </select>
            </div>

            <div class="auth-container-choose">
                <span>Вулиця</span>
                <div class="order__input-price">
                    <input type="text" placeholder="Введіть район" name="street" v-model="formData.location_street">
                </div>
            </div>
        </div>

        <hr class="order-hr">

        <div class="order-container">
            <h2 class="main-title">
                Дата виконання замовлення
            </h2>

            <ul class="order__dates">
                <label @click="formData.due_date = date" :data-due_date="date.getDate()">
                    <input class="order__date-choose checkbox" type="radio" name="blur"
                        style="display: none;">
                    <div class="order-container-radio">
                        <span class="order__date">{{ HELPERS.getMonthName( date.getMonth() ) }}</span>
                        <p class="order__date-number">{{ date.getDate() }}</p>
                        <p class="order__date-now">Сьогодні</p>
                    </div>
                </label>

                <label @click="formData.due_date = tomorrow" :data-due_date="tomorrow.getDate()">
                    <input class="order__date-choose checkbox" type="radio"
                        name="blur" style="display: none;">
                    <div class="order-container-radio">
                        <span class="order__date">{{ HELPERS.getMonthName( tomorrow.getMonth() ) }}</span>
                        <p class="order__date-number">{{ tomorrow.getDate() }}</p>
                        <p class="order__date-now">Завтра</p>
                    </div>
                </label>

                <label @click="formData.due_date = days2after" :data-due_date="days2after.getDate()">
                    <input class="order__date-choose checkbox" type="radio" name="blur"
                        style="display: none;">
                    <div class="order-container-radio">
                        <span class="order__date">{{ HELPERS.getMonthName( days2after.getMonth() ) }}</span>
                        <p class="order__date-number">{{ days2after.getDate() }}</p>
                        <p class="order__date-now">Післязавтра</p>
                    </div>
                </label>
            </ul>

            <div class="auth-container-choose">
                <span>Час виконання завдання</span>
                <ul class="order__times">
                    <label @click="formData.due_time = 'any'" data-due_time="any">
                        <input class="order__time" type="radio" name="x" value="00">
                        <span>Будь-який час</span>
                    </label>
                    <label @click="formData.due_time = '8:00-12:00'" data-due_time="8:00-12:00">
                        <input class="order__time" type="radio" name="x" value="01" checked>
                        <span>8:00 – 12:00</span>
                    </label>
                    <label @click="formData.due_time = '12:00-16:00'" data-due_time="12:00-16:00">
                        <input class="order__time" type="radio" name="x" value="02">
                        <span>12:00 – 16:00</span>
                    </label>
                    <label @click="formData.due_time = '16:00-20:00'" data-due_time="16:00-20:00">
                        <input class="order__time" type="radio" name="x" value="03">
                        <span>16:00 – 20:00</span>
                    </label>
                </ul>
            </div>

        </div>

        <hr class="order-hr">

        <div class="order-container">

            <h2 class="main-title">
                Орієнтовна вартість роботи
            </h2>

            <p class="order__desc-form">Ви можете погодити з виконавцем іншу вартість в процесі переговорів</p>

            <div class="order__input-price">
                <input type="number" placeholder="100 000" min="0" v-model="formData.price_start">
                <span>₫</span>
            </div>

        </div>

        <hr class="order-hr">

        <div class="order-container child-no-padding">
            <h2 class="main-title" :style="[user == null ? {'margin-bottom': '0'} : '']">
                Контактні дані
            </h2>

            <OrderAppAuth></OrderAppAuth>

            <div class="auth-container-choose rules-agreement">
                <div class="auth-accept">
                    <label class="container">
                        <input type="checkbox" name="rules" id="checkbox" v-model="formData.rules_accepted">
                        <span class="checkmark"></span>
                    </label>
                    <label for="checkbox"><a href="/agreement" target="_blank">З правилами сервісу
                            погоджуюсь</a></label>
                </div>
            </div>

            <div class="auth-container-choose">
                <button type="submit" class="btn btn-big" id="btn-create-order" :class="[userStore.user != null && userStore.user.full_name != '' && userStore.user.phone_number != '' && userStore.user.email != '' ? 'btn-green' : 'btn-green-outline']">Редагувати замовлення</button>
            </div>

            <transition name="fade">
                <p class="error" style="margin-top: 20px;" v-if="responseError.error != null">{{ responseError.error }}</p>
            </transition>

        </div>
    </form>
</template>
