<script setup>
import { ref, reactive, nextTick } from 'vue';
import Modal from '../base/Modal.vue';

const props = defineProps({
    orders: {
        type: [Object, null],
        required: true
    },
    tasks: {
        type: [Object, null],
        required: true
    },
    locations: {
        type: [Object, null],
        required: true
    },
    registration_status: {
        type: [Number, null]
    }
});

const orders = ref(props.orders);   // Orders
const fullText = ref(new Map());   // Full texts
const customerPopup = ref(null);    // Customer popup
const isFilterResultEmpty = ref(false); // Is filter result empty
const sortType = ref('date');   // Sorting default time
const isFilterOpened = ref(false);  // Is filter opened?
const filterSelected = reactive({   // Selected filters
    locations: {},
    tasks: {}
});
const totalFiltersAmount = ref(0);  // Total filters amount

const customer = reactive({
    full_name: '',
    total_registration_time: '',
    user_id: ''
}); // Customer's info

const error = ref(false);   // Error

/* Show info about the customer */
const showCustomerInfo = (key) => {
    [customer.full_name, customer.total_registration_time, customer.user_id] = [props.orders[key].customer.full_name, props.orders[key].customer.total_registration_time, props.orders[key].customer.user_id];
    customerPopup.value.openModal();
}

/** Make offer */
const makeOffer = (order_id) => {
    axios.post('/offer/make', { order_id })
        .then(function (response) {
            console.log(response.data);
            // If error
            if (response.data.error != null) {
                error.value = {
                    order_id: order_id,
                    message: response.data.error
                };
                return;
            }

            // If success
            location.reload();
        }).catch(function (error) {
            console.log(error);
        });
}

/** Sort orders */
const sort = (type) => {
    switch (type) {
        // Sort by price ASC
        case 'price':
            orders.value = orders.value.sort((a, b) => {
                let priceA = parseFloat(a.sorted_attributes['order--price_start'][0].value);
                let priceB = parseFloat(b.sorted_attributes['order--price_start'][0].value);

                return priceB - priceA;
            });
            sortType.value = 'price';

            break;
        // Sort by date ASC
        case 'date':
            orders.value = orders.value.sort((a, b) => {
                let da = new Date(a.created_at_ts),
                    db = new Date(b.created_at_ts);

                return db - da;
            });
            sortType.value = 'date';
            break;
        default:
            break;
    }
}

/** Open filter */
const openFilter = () => {
    isFilterOpened.value = true;
}

/** Toggle filter */
const toggleFilter = (type, key, id) => {
    if( !filterSelected[type].hasOwnProperty(key) ) filterSelected[type][key] = [];

    if(filterSelected[type][key].includes(id)){
        filterSelected[type][key] = filterSelected[type][key].filter(e => e !== id);
        totalFiltersAmount.value--;
    } else {
        filterSelected[type][key].push(id);
        totalFiltersAmount.value++;
    }

    if( filterSelected[type][key].length < 1 ) delete filterSelected[type][key];
}

/** Apply filter */
const applyFilters = () => {

    let locations = [];
    for (const [key, value] of Object.entries(filterSelected.locations)) {
        value.forEach(element => {
            locations.push(element);
        });
    }

    let tasks = [];
    for (const [key, value] of Object.entries(filterSelected.tasks)) {
        value.forEach(element => {
            tasks.push(element);
        });
    }

    axios.post('/orders/new/filter', { tasks, locations, sort_by: sortType.value })
        .then(function (response) {
            console.log(response.data);
            orders.value = response.data;
            isFilterResultEmpty.value = Object.keys(orders.value).length > 0 ? false : true;
            isFilterOpened.value = false;
        }).catch(function (error) {
            console.log(error);
        });
}

/** Reset filters */
const resetFilters = () => {
    filterSelected.tasks = filterSelected.locations = {};
    totalFiltersAmount.value = 0;

    nextTick( () => {
        let checkboxes = document.querySelectorAll('.order__details-input.checkbox');
        checkboxes.forEach( (value, key) => value.checked = false );
    } );
}

</script>

<template>
    <div class="order-container" v-if="Object.keys(orders).length > 0 || isFilterResultEmpty">
        <h2 class="main-title">
            Замовлення що можна обрати
        </h2>

        <ul class="order__sorting">
            <p>Сортувати</p>
            <label><input class="order__time" type="radio" name="x" value="00" @click="sort('date')" checked>
                <span>По даті
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1L6 9M6 9L10 5M6 9L2 5" stroke-width="1.95" stroke-linecap="square"
                            stroke-linejoin="round" />
                    </svg>
                </span>
            </label>
            <label><input class="order__time" type="radio" name="x" value="01" @click="sort('price')">
                <span>Ціні
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1L6 9M6 9L10 5M6 9L2 5" stroke-width="1.95" stroke-linecap="square"
                            stroke-linejoin="round" />
                    </svg>
                </span>
            </label>
        </ul>

        <ul class="order__sorting order__sorting-change">
            <div class="order__sorting-inside open-menu-contact-one" @click="openFilter">
                <p>Фільтр</p>

                <div class="order__sorting-check">
                    <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M28 1H1M19 10H1M22 19H1" stroke="#1A1A1A" stroke-width="1.95" stroke-linecap="square"
                            stroke-linejoin="round" />
                    </svg>

                    <div v-if="totalFiltersAmount > 0">
                        <span>{{ totalFiltersAmount }}</span>
                    </div>
                </div>
            </div>

            <!-- <span class="order__sorting-active">Категорія</span>

                  <span class="order__sorting-active">Місто</span>

                  <span class="order__sorting-active">Район</span> -->
        </ul>
    </div>

    <p class="info" v-else>Наразі немає доступних замовлень</p>

    <div class="order__author" v-for="(value, index) in orders">
        <p class="order__author-title">{{ value.sorted_attributes['order--description_short'][0].value }}</p>

        <div class="order-container-more-text">
            <p class="order__author-desc" :class="{ 'height-auto': fullText.has(index) }">{{
                value.sorted_attributes['order--description_full'][0].value }}</p>
            <button class="text-button text-minus" v-if="!fullText.has(index)" @click="fullText.set(index, 1)">Читати
                все</button>
            <button class="text-button text-more" v-else @click="fullText.delete(index)">згорнути</button>
        </div>

        <ul class="order__author-info">
            <li>
                <span>Дата</span>
                <p>{{ value.human_date }}, <br class="mobile-break">{{ value.sorted_attributes['order--due_time'][0].value
                }}</p>
            </li>

            <li>
                <span>Місто</span>
                <p>{{ value.location.city }}</p>
            </li>

            <li>
                <span>Район</span>
                <p>{{ value.location.district }}</p>
            </li>

            <li>
                <span>Вулиця</span>
                <p>{{ value.sorted_attributes['order--location_street'][0].value }}</p>
            </li>
        </ul>

        <hr class="order-hr">

        <ul class="order__author-reviews">
            <img src="../../../images/order-avatar.png" alt="">

            <div class="order__author-inside">
                <p class="order__author-name">{{ value.customer != null ? value.customer.full_name : 'Deleted Profile' }}
                </p>
                <p class="order__author-profile" @click="showCustomerInfo(index)" v-if="value.customer != null">Профіль
                    замовника</p>

                <div class="order__author-inside-reviews">
                    <div>
                        <p>3</p>
                        <span>відгуків</span>
                    </div>

                    <div>
                        <p>100%</p>
                        <span>позитивних</span>
                    </div>
                </div>
            </div>
        </ul>

        <hr class="order-hr">

        <p class="order__price">{{ value.sorted_attributes['order--price_start'][0].value }} ₫</p>

        <button class="btn btn-big btn-green-outline" @click="makeOffer(value.order_id)"
            :disabled="registration_status == 5">Обрати</button>
        <!-- <a href="/" class="auth__tg auth__tg-max auth-tg-border" @click="">Обрати</a> -->

        <transition name="fade">
            <p class="error error_mt text-center" v-if="error && error.order_id == value.order_id">{{ error.message }}</p>
        </transition>
    </div>


    <!-- MODALS -->
    <!-- Popup `Customer info` -->
    <Modal id="popup-customer-info" ref="customerPopup" :close_button="true" :opened="false">
        <template #content>
            <h2 class="popup__title">{{ customer.full_name }}</h2>

            <ul class="popup__infos">
                <li>
                    <span>В сервісі</span>
                    <span>Замовлень</span>
                    <span>Позитивних відгуків</span>
                </li>

                <li>
                    <p>{{ customer.total_registration_time }} міс</p>
                    <p>5</p>
                    <p>80%</p>
                </li>
            </ul>

            <a :href='`/profile/customer/${customer.user_id}`'><button class="auth__tg auth__tg-max">Детальніше</button></a>
        </template>
    </Modal>

    <!-- menu one -->
    <div class="popup menu-contact-one" :class="{'popup_opened': isFilterOpened}">
        <form class="popup__group">
            <button class="button popup__close" type="button" @click="isFilterOpened = false"></button>
            <div class="popup__menu-close">
                <p>Перелік послуг</p>

                <div @click="resetFilters">
                    <span>Скинути все</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 2L2 14M2 2L14 14" stroke="#21A150" stroke-width="1.95" stroke-linecap="square"
                            stroke-linejoin="round" />
                    </svg>
                </div>
            </div>

            <details class="order__details" v-for="(value, key) in tasks">
                <summary>
                    <div>
                        <h3>{{ key }}</h3>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                </summary>
                <div class="details-info">
                    <ul>
                        <li>
                            <label v-for="(_value, _key) in value">
                                <input class="order__details-input checkbox" type="checkbox" :checked="filterSelected.tasks.hasOwnProperty(_key) && filterSelected.tasks[_key].includes(_value.id)" name="blur" style="display: none;" @click="toggleFilter('tasks', key, _value.id)">
                                <span>{{ _value.subcategory_1 }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </details>

            <hr class="order-hr">

            <div class="popup__menu-close">
                <p>Локація</p>
            </div>

            <details class="order__details" v-for="(value, key) in locations">
                <summary>
                    <div>
                        <h3>{{ key }}</h3>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                </summary>
                <div class="details-info">
                    <ul>
                        <li>
                            <label v-for="(_value, _key) in value">
                                <input class="order__details-input checkbox" type="checkbox" :checked="filterSelected.locations.hasOwnProperty(_key) && filterSelected.locations[_key].includes(_value.id)" name="blur" style="display: none;" @click="toggleFilter('locations', key, _value.id)">
                                <span>{{ _value.district }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </details>


            <button type="button" class="auth__tg auth__tg-max menu_close" @click="applyFilters">Підтвердити</button>
        </form>

    </div>
    <!-- menu one -->
</template>
