<script setup>
import { ref, reactive, onMounted } from 'vue';
import { HELPERS } from '../../helpers';
import Modal from '../base/Modal.vue';

const props = defineProps({
    orders: {
        type: [Object, null],
        required: true
    },
});

console.log(props.orders);

const fullText = ref(new Map());   // Full texts
const performerPopup = ref(null);    // Customer popup
const performer = reactive({
    full_name: '',
    total_registration_time: '',
    performer_site_user_id: ''
}); // Performer info
const orders = ref(props.orders);   // Ref orders

/* Response error */
const responseError = reactive({
    status: false,
    message: ''
});

/* Show info about the customer */
const showPerformerInfo = (data) => {
    [performer.full_name, performer.total_registration_time, performer.performer_site_user_id] = data;
    performerPopup.value.openModal();
}

/* Cancel order */
const cancelOrder = (order_id) => {
    if (order_id == null) return;

    axios.post('/cabinet/customer/order/cancel', { order_id })
        .then(function (response) {
            console.log(response.data);
            // If error
            if (response.data.error != null) {
                responseError.message = response.data.error;
                responseError.status = true;
                responseError.order_id = order_id;
            } else {
                location.reload();
            }
        }).catch(function (error) {
            console.log(error);
        });
}

/* Format date */
const formatPerformerDate = (created_at) => {
    let date = new Date(Date.parse(created_at));
    return `${date.getDate()} ${HELPERS.getMonthName(date.getMonth())} ${date.getFullYear()}`;
}

/** Choose performer */
const choosePerformer = (offer_id, orderId) => {
    if (offer_id == null) {
        responseError.order_id = orderId;
        responseError.message = 'Unknown performer';
        return;
    }

    axios.post('/performer/choose', { offer_id })
        .then(function (response) {
            console.log(response.data);
            // If error
            if (response.data.error != null) {
                responseError.value = {
                    order_id: orderId,
                    message: response.data.error
                };
                return;
            }
            // If success
            location.reload();
        }).catch(function (error) {
            console.log(error);
        });
}

onMounted(() => {
    setInterval(() => {
        axios.get('/cabinet/customer/proposals')
            .then(function (response) {
                console.log(response.data);
                orders.value = response.data;
            }).catch(function (error) {
                console.log(error);
            });
    }, 5000);   // Check for new proposals every 5 seconds
});
</script>

<template>
    <h2 class="main-title w-mob-100 padding-mob-x-30" v-if="orders.length > 0">
        Знайдені виконавці
    </h2>

    <p class="info" v-else>
        Немає замовлень в роботі. Але, ви завжди можете <a href="/app/order" class="link-orange">створити нове</a>.
    </p>

    <transition-group name="fade">
        <div class="order__author" v-for="(value, index) in orders">
            <a class="order__author-title order__author-title-decor">
                {{ value.sorted_attributes['order--description_short'][0].value }}
            </a>

            <p class="order__author-money">{{ value.sorted_attributes['order--price_start'][0].value }} ₫</p>

            <div class="order__performer-search" v-if="Object.keys(value.offers).length < 1">
                <img src="../../../images/icons/order-wait.svg" alt="" class="img-adaptive">
                <span>Шукаємо виконавця</span>
            </div>

            <ul class="order__buttons">
                <a href="#" @click.prevent="cancelOrder(value.order_id)"
                    class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-red">Відмінити<br>
                    замовлення</a>
                <a :href="`/app/order/edit/${value.order_id}`"
                    class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-yellow">Редагувати<br>
                    замовлення</a>
            </ul>

            <div v-if="Object.keys(value.offers).length > 0">
                <div v-for="(v, i) in value.offers">
                    <hr class="order-hr">
                    <ul class="order__author-reviews">
                        <div class="order__author-reviews-up">
                            <!-- <img src="../../../images/order-avatar.png" alt="Avatar" loading="lazy"> -->
                            <img class="user-order-avatar"
                                :src="v.performer_sorted_attributes['performer--photo_avatar'][0].value" alt="Avatar"
                                loading="lazy">
                        </div>

                        <div class="order__author-inside">
                            <p class="order__author-name">{{ v.full_name }}</p>
                            <p class="order__author-profile open-popup-contact-one"
                                @click="showPerformerInfo([v.full_name, v.user_created_at_ts, v.performer_site_user_id])">
                                Профіль виконавця</p>

                            <div class="order__author-inside-reviews">
                                <div>
                                    <p>3</p>
                                    <span>відгуків</span>
                                </div>

                                <div>
                                    <p>100%</p>
                                    <span>позитивних</span>
                                </div>
                            </div>
                        </div>
                    </ul>

                    <div class="order-container-more-text">
                        <!--                     <p class="order__author-desc">{{ v.performer_sorted_attributes['performer--about'][0].value }}</p>
                    <button class="text-button text-minus">Читати все</button>
                    <button class="text-button text-more" style="display: none;">згорнути</button> -->
                        <p class="order__author-desc" :class="{ 'height-auto': fullText.has(v.performer_id) }">{{
                            v.performer_sorted_attributes['performer--about'][0].value }}</p>
                        <button class="text-button text-minus" v-if="!fullText.has(v.performer_id)"
                            @click="fullText.set(v.performer_id, 1)">Читати все</button>
                        <button class="text-button text-more" v-else
                            @click="fullText.delete(v.performer_id)">згорнути</button>
                    </div>

                    <ul class="order__author-info">
                        <li>
                            <span>На сервісі</span>
                            <p>з {{ formatPerformerDate(v.user_created_at_ts) }}</p>
                        </li>

                        <li>
                            <span>Документи</span>
                            <p v-if="v.registration_status == 4">
                                <img src="../../../images/icons/icon-verified.svg" alt="">
                                Перевірено
                            </p>
                            <p v-else>
                                <img src="../../../images/icons/icon-unverified.svg" alt="">
                                Не надано
                            </p>
                        </li>
                    </ul>

                    <button class="btn btn-big btn-green-outline choose-performer"
                        @click="choosePerformer(v.offer_id, value.order_id)">Обрати</button>
                </div>
            </div>

            <div class="error" v-if="responseError.status != false && responseError.order_id == value.order_id">{{
                responseError.message }}</div>
        </div>
    </transition-group>


    <!-- MODALS -->
    <Modal id="popup-performer-info" ref="performerPopup" :close_button="true" :opened="false">
        <template #content>
            <h2 class="popup__title">{{ performer.full_name }}</h2>

            <ul class="popup__infos">
                <li>
                    <span>В сервісі</span>
                    <span>Замовлень</span>
                    <span>Позитивних відгуків</span>
                </li>

                <li>
                    <p>{{ HELPERS.getTotalMonths(performer.total_registration_time) }} міс</p>
                    <p>5</p>
                    <p>80%</p>
                </li>
            </ul>

            <a :href='`/profile/performer/${performer.performer_site_user_id}`'><button
                    class="auth__tg auth__tg-max">Детальніше</button></a>
        </template>
    </Modal>

    <!-- <div class="order__author">
        <a class="order__author-title order__author-title-decor">
            Провести трубы к станциям, подключить сантехнику
        </a>

        <p class="order__author-money">100 000 000 ₫</p>

        <ul class="order__buttons">
            <a href="/" class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-red">Відмінити<br>
                замовлення</a>
            <a href="/app/order/create"
                class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-yellow">Редагувати<br>
                замовлення</a>
        </ul>

        <hr class="order-hr">

        <ul class="order__author-reviews">
            <div class="order__author-reviews-up">
                <img src="../../../images/order-avatar.png" alt="Avatar" loading="lazy">
            </div>

            <div class="order__author-inside">
                <p class="order__author-name">Ярослав П.</p>
                <p class="order__author-profile open-popup-contact-one">Профіль виконавця</p>

                <div class="order__author-inside-reviews">
                    <div>
                        <p>3</p>
                        <span>відгуків</span>
                    </div>

                    <div>
                        <p>100%</p>
                        <span>позитивних</span>
                    </div>
                </div>
            </div>
        </ul>

        <div class="order-container-more-text">
            <p class="order__author-desc">Доброго дня, нам горе майстер підключив ще один вихід для портоихід для
                портоихід для пор Доброго дня, нам горе майстер підключив ще один вихід для портоихід для
                портоихід для пор</p>
            <button class="text-button text-minus">Читати все</button>
            <button class="text-button text-more" style="display: none;">згорнути</button>
        </div>

        <ul class="order__author-info">
            <li>
                <span>На свервісі</span>
                <p>з 25 Трав 2023</p>
            </li>

            <li>
                <span>Документи</span>
                <p>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.99983 7.00001C2.8758 6.44111 2.83948 5.54958 2.99983 5.00001C3.16018 4.45043 2.59474 3.40441 2.99983 3.00001C3.40492 2.59561 4.45014 3.15927 4.99983 3.00001C5.54952 2.84075 6.44141 2.87476 6.99983 3.00001C7.30719 2.51912 7.49938 1.27413 7.99983 1.00001C8.50028 0.725882 9.42927 1.00001 9.99983 1.00001C10.5704 1.00001 10.4994 0.725882 10.9998 1.00001C11.5003 1.27413 11.6925 2.51912 11.9998 3.00001C12.5591 2.87422 13.4493 2.84036 13.9998 3.00001C14.5504 3.15966 15.5945 2.59451 15.9998 3.00001C16.4052 3.4055 15.8402 4.44922 15.9998 5.00001C16.1594 5.55079 16.1256 6.44052 15.9998 7.00001C16.4805 7.30749 17.7258 7.49936 17.9998 8.00001C18.2738 8.50066 17.9998 8.42922 17.9998 9.00001C17.9998 9.57079 18.2738 10.4994 17.9998 11C17.7258 11.5007 16.4805 11.6925 15.9998 12C16.125 12.5586 16.159 13.4501 15.9998 14C15.8406 14.5499 16.4041 15.5948 15.9998 16C15.5956 16.4053 14.5492 15.8396 13.9998 16C13.4505 16.1604 12.5585 16.1241 11.9998 16C11.6929 16.4827 11.5011 17.7247 10.9998 18C10.4985 18.2754 10.5717 18 9.99983 18C9.42793 18 8.50114 18.2754 7.99983 18C7.49853 17.7247 7.30679 16.4827 6.99983 16C6.44141 16.1253 5.54952 16.1593 4.99983 16C4.45014 15.8407 3.40492 16.4044 2.99983 16C2.59474 15.5956 3.16018 14.5496 2.99983 14C2.83948 13.4504 2.8758 12.5589 2.99983 12C2.51544 11.6933 1.27631 11.5024 0.999831 11C0.723356 10.4976 0.999831 9.57347 0.999831 9.00001C0.999831 8.42654 0.723356 8.50237 0.999831 8.00001C1.27631 7.49765 2.51544 7.30668 2.99983 7.00001Z"
                            fill="#21A150" />
                        <path d="M7 10L9 11L12 8" stroke="white" stroke-width="1.95" stroke-linecap="square"
                            stroke-linejoin="round" />
                    </svg>
                    Перевірено
                </p>
            </li>
        </ul>

        <a href="/" class="auth__tg auth__tg-max auth-tg-border">Обрати</a>

        <hr class="order-hr">

        <ul class="order__author-reviews">
            <div class="order__author-reviews-up">
                <img src="../../../images/order-avatar-2.png" alt="Avatar" loading="lazy">
            </div>

            <div class="order__author-inside">
                <p class="order__author-name">Ярослав П.</p>
                <p class="order__author-profile open-popup-contact-one">Профіль виконавця</p>

                <div class="order__author-inside-reviews">
                    <div>
                        <p>999</p>
                        <span>відгуків</span>
                    </div>

                    <div>
                        <p>100%</p>
                        <span>позитивних</span>
                    </div>
                </div>
            </div>
        </ul>

        <div class="order-container-more-text">
            <p class="order__author-desc">Доброго дня, нам горе майстер підключив ще один вихід для портоихід для
                портоихід для пор Доброго дня, нам горе майстер підключив ще один вихід для портоихід для
                портоихід для пор</p>
            <button class="text-button text-minus">Читати все</button>
            <button class="text-button text-more" style="display: none;">згорнути</button>
        </div>

        <ul class="order__author-info">
            <li>
                <span>На свервісі</span>
                <p>з 2 Лют 2022</p>
            </li>

            <li>
                <span>На свервісі</span>
                <p>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.99983 7.00001C2.8758 6.44111 2.83948 5.54958 2.99983 5.00001C3.16018 4.45043 2.59474 3.40441 2.99983 3.00001C3.40492 2.59561 4.45014 3.15927 4.99983 3.00001C5.54952 2.84075 6.44141 2.87476 6.99983 3.00001C7.30719 2.51912 7.49938 1.27413 7.99983 1.00001C8.50028 0.725882 9.42927 1.00001 9.99983 1.00001C10.5704 1.00001 10.4994 0.725882 10.9998 1.00001C11.5003 1.27413 11.6925 2.51912 11.9998 3.00001C12.5591 2.87422 13.4493 2.84036 13.9998 3.00001C14.5504 3.15966 15.5945 2.59451 15.9998 3.00001C16.4052 3.4055 15.8402 4.44922 15.9998 5.00001C16.1594 5.55079 16.1256 6.44052 15.9998 7.00001C16.4805 7.30749 17.7258 7.49936 17.9998 8.00001C18.2738 8.50066 17.9998 8.42922 17.9998 9.00001C17.9998 9.57079 18.2738 10.4994 17.9998 11C17.7258 11.5007 16.4805 11.6925 15.9998 12C16.125 12.5586 16.159 13.4501 15.9998 14C15.8406 14.5499 16.4041 15.5948 15.9998 16C15.5956 16.4053 14.5492 15.8396 13.9998 16C13.4505 16.1604 12.5585 16.1241 11.9998 16C11.6929 16.4827 11.5011 17.7247 10.9998 18C10.4985 18.2754 10.5717 18 9.99983 18C9.42793 18 8.50114 18.2754 7.99983 18C7.49853 17.7247 7.30679 16.4827 6.99983 16C6.44141 16.1253 5.54952 16.1593 4.99983 16C4.45014 15.8407 3.40492 16.4044 2.99983 16C2.59474 15.5956 3.16018 14.5496 2.99983 14C2.83948 13.4504 2.8758 12.5589 2.99983 12C2.51544 11.6933 1.27631 11.5024 0.999831 11C0.723356 10.4976 0.999831 9.57347 0.999831 9.00001C0.999831 8.42654 0.723356 8.50237 0.999831 8.00001C1.27631 7.49765 2.51544 7.30668 2.99983 7.00001Z"
                            fill="#F24C3D" />
                        <path d="M12 8L8 12M8 8L12 12" stroke="white" stroke-width="1.95" stroke-linecap="square"
                            stroke-linejoin="round" />
                    </svg>
                    Не надано
                </p>
            </li>
        </ul>

        <a href="/" class="auth__tg auth__tg-max auth-tg-border">Обрати</a>
    </div>

    <div class="order__author order__author-without">
        <a class="order__author-title order__author-title-decor">
            Діагностика двігуна
        </a>

        <p class="order__author-money">999 000 000 ₫</p>

        <ul class="order__buttons">
            <a href="/" class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-red">Відмінити<br>
                замовлення</a>
            <a href="/app/order/create"
                class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-yellow">Редагувати<br>
                замовлення</a>
        </ul>

        <hr class="order-hr">

        <ul class="order__author-reviews">
            <div class="order__author-reviews-up">
                <img src="../../../images/order-avatar.png" alt="Avatar" loading="lazy">
            </div>

            <div class="order__author-inside">
                <p class="order__author-name">Ярослав П.</p>
                <p class="order__author-profile open-popup-contact-one">Профіль виконавця</p>

                <div class="order__author-inside-reviews">
                    <div>
                        <p>3</p>
                        <span>відгуків</span>
                    </div>

                    <div>
                        <p>100%</p>
                        <span>позитивних</span>
                    </div>
                </div>
            </div>
        </ul>

        <div class="order-container-more-text">
            <p class="order__author-desc">Доброго дня, нам горе майстер підключив ще один вихід для портоихід для
                портоихід для пор Доброго дня, нам горе майстер підключив ще один вихід для портоихід для
                портоихід для пор</p>
            <button class="text-button text-minus">Читати все</button>
            <button class="text-button text-more" style="display: none;">згорнути</button>
        </div>

        <ul class="order__author-info">
            <li>
                <span>На свервісі</span>
                <p>з 25 Трав 2023</p>
            </li>

            <li>
                <span>Документи</span>
                <p>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.99983 7.00001C2.8758 6.44111 2.83948 5.54958 2.99983 5.00001C3.16018 4.45043 2.59474 3.40441 2.99983 3.00001C3.40492 2.59561 4.45014 3.15927 4.99983 3.00001C5.54952 2.84075 6.44141 2.87476 6.99983 3.00001C7.30719 2.51912 7.49938 1.27413 7.99983 1.00001C8.50028 0.725882 9.42927 1.00001 9.99983 1.00001C10.5704 1.00001 10.4994 0.725882 10.9998 1.00001C11.5003 1.27413 11.6925 2.51912 11.9998 3.00001C12.5591 2.87422 13.4493 2.84036 13.9998 3.00001C14.5504 3.15966 15.5945 2.59451 15.9998 3.00001C16.4052 3.4055 15.8402 4.44922 15.9998 5.00001C16.1594 5.55079 16.1256 6.44052 15.9998 7.00001C16.4805 7.30749 17.7258 7.49936 17.9998 8.00001C18.2738 8.50066 17.9998 8.42922 17.9998 9.00001C17.9998 9.57079 18.2738 10.4994 17.9998 11C17.7258 11.5007 16.4805 11.6925 15.9998 12C16.125 12.5586 16.159 13.4501 15.9998 14C15.8406 14.5499 16.4041 15.5948 15.9998 16C15.5956 16.4053 14.5492 15.8396 13.9998 16C13.4505 16.1604 12.5585 16.1241 11.9998 16C11.6929 16.4827 11.5011 17.7247 10.9998 18C10.4985 18.2754 10.5717 18 9.99983 18C9.42793 18 8.50114 18.2754 7.99983 18C7.49853 17.7247 7.30679 16.4827 6.99983 16C6.44141 16.1253 5.54952 16.1593 4.99983 16C4.45014 15.8407 3.40492 16.4044 2.99983 16C2.59474 15.5956 3.16018 14.5496 2.99983 14C2.83948 13.4504 2.8758 12.5589 2.99983 12C2.51544 11.6933 1.27631 11.5024 0.999831 11C0.723356 10.4976 0.999831 9.57347 0.999831 9.00001C0.999831 8.42654 0.723356 8.50237 0.999831 8.00001C1.27631 7.49765 2.51544 7.30668 2.99983 7.00001Z"
                            fill="#21A150" />
                        <path d="M7 10L9 11L12 8" stroke="white" stroke-width="1.95" stroke-linecap="square"
                            stroke-linejoin="round" />
                    </svg>
                    Перевірено
                </p>
            </li>
        </ul>

        <a href="/" class="auth__tg auth__tg-max auth-tg-border">Обрати</a>
    </div>

    <div class="order__author">
        <a class="order__author-title order__author-title-decor">
            Провести трубы к станциям, подключить сантехнику
        </a>

        <p class="order__author-money">100 000 000 ₫</p>

        <div class="order__performer-search">
            <img src="../../../images/icons/order-wait.svg" alt="" class="img-adaptive">
            <span>Шукаємо виконавця</span>
        </div>

        <ul class="order__buttons">
            <a href="/" class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-red">Відмінити<br>
                замовлення</a>
            <a href="/app/order/create"
                class="auth__tg auth__tg-profile auth-tg-border auth-tg-border-yellow">Редагувати<br>
                замовлення</a>
        </ul>
    </div> --></template>
