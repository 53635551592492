<script setup>

import { ref, reactive, onMounted } from 'vue';

const props = defineProps({
    tasks: {
        type: Object
    },
    selected: {
        type: [Object, null],
        required: true
    },
});

const selectedTasks = reactive({}); // Selected tasks
const hiddenBlocks = ref( Object.keys(props.tasks) );   // Hidden blocks
const error = ref(false);   // Error

console.log(props.tasks);

onMounted(() => {
    // Fill selected tasks
    // Old
/*     if(props.selected != null){
        for (const [key, value] of Object.entries(props.selected)) {
            for ( const [_key, _value] of Object.entries(value) ){
                toggleTask(key, _value.id);
            }
        }
    } */

    //console.log(props.selected);
    let storageSelected = localStorage.getItem('tasks');

    if(props.selected != null && Object.keys(props.selected).length > 0 && storageSelected == null) {
        localStorage.setItem('tasks', JSON.stringify(props.selected) );
        storageSelected = localStorage.getItem('tasks');
    }

    if(storageSelected != null){
        try {
            storageSelected = JSON.parse(storageSelected);
            for (const [key, value] of Object.entries(storageSelected)) {
                for ( const [_key, _value] of Object.entries(value) ){
                    toggleTask(key, _value.id ?? _value);
                }
            }
        } catch (e){}
    }
});

/** Toggle task */
const toggleTask = (key, id) => {
   if( !selectedTasks.hasOwnProperty(key) ) selectedTasks[key] = [];

    if(selectedTasks[key].includes(id)){
        selectedTasks[key] = selectedTasks[key].filter(e => e !== id);
    } else {
        selectedTasks[key].push(id);
    }

    if( selectedTasks[key].length < 1 ) delete selectedTasks[key];

    //console.log(selectedTasks);
}

/** Save tasks */
const saveTasks = () => {
    error.value = false;

    if( Object.keys(selectedTasks).length < 1 ) {
        error.value = 'You need to select at least 1 task';
        return;
    }

    localStorage.setItem('tasks', JSON.stringify(selectedTasks) );
    location.href = '/cabinet/performer/questionary';

    // Old

    /* let tasks = [];
    for (const [key, value] of Object.entries(selectedTasks)) {
        value.forEach(element => {
            tasks.push(element);
        });
    }
    axios.post('/performer/tasks/save', { tasks })
        .then(function (response) {
            console.log(response.data);
            // If error
            if(response.data.error != null){
                error.value = response.data.error;
                return;
            }

            // If success
            location.href = response.data.success;  // If success, redirect to performer questionary page
        }).catch(function (error) {
            console.log(error);
        }); */
}

/** Toggle group */
const toggleGroup = (item) => {
    if( hiddenBlocks.value.includes(item) ){
        hiddenBlocks.value = hiddenBlocks.value.filter(e => e !== item);
    } else {
        hiddenBlocks.value.push(item);
    }
}

/** Check if can display total selected tasks */
const canDisplayTotal = (key) => {
    return selectedTasks.hasOwnProperty(key) && hiddenBlocks.value.includes(key);
}

</script>

<template>
    <div class="main-container order" v-for="(value, key) in tasks">
        <transition name="fade">
            <div class="order-choose" v-if="canDisplayTotal(key)">
                <span>{{  selectedTasks[key].length  }}</span>
            </div>
        </transition>
        <div class="order-container order-container-service">
            <details class="order__details order__details-choose" @click="toggleGroup(key)">
                <summary>
                    <div>
                        <img :src="value[0].icon" alt="">
                        <h3>{{ key }}</h3>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                </summary>
                <div class="details-info">
                    <ul>
                        <li>
                            <label v-for="(_value, _key) in value">
                                <input class="order__details-input checkbox" type="checkbox" :checked="selectedTasks.hasOwnProperty(key) && selectedTasks[key].includes(_value.id)"
                                    name="blur" style="display: none;" @click="toggleTask(key, _value.id)">
                                <span>{{ _value.subcategory_1 }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </details>
        </div>
    </div>

    <div class="main-container auth order auth__without-width">
        <div class="order-container order-container-index">

            <div class="order-main-container-button">
                <button class="order__main-order" @click="saveTasks">Підтвердити</button>
            </div>

            <transition name="fade">
                <p class="error text-center" style="margin-top: 10px;" v-if="error">{{ error }}</p>
            </transition>
        </div>
    </div>
</template>
