/**
 * Helpers module
 */

export const HELPERS = {

    /** Get name of month */
    getMonthName: (month) => {
        const months = ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"];
        return months[month];
    },

    /** Find in map */
    findInMap: (map, value) => {
        for (let [k, v] of map) {
          if (v === value) {
            return true;
          }
        }
        return false;
    },

    /** Total months from date */
    getTotalMonths: (date) => {
        let now = new Date();
        let parsedDate = new Date( Date.parse(date) );

        let diffInTime = now.getTime() - parsedDate.getTime();
        let diffInDays = Math.round(diffInTime / (24 * 60 * 60 * 1000));

        return Math.round( Math.max(1, diffInDays / 30 ) );
    },
}
