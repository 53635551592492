<script setup>
    import { ref, reactive, onMounted } from 'vue';

    const props = defineProps({
        phone: {
            type: [String, null],
            required: true
        }
    });

    const formShown = ref(false);   // Is block shown?
    const formData = reactive({
        full_name: '',
        phone_number: props.phone,
        email: '',
        rules_accepted: false
    }); // Form data

    const hasErrors = ref(false);   // Has form errors?
    const inputsErrors = reactive({
        full_name: {
            status: false,
            message: 'Incorrect data entered'
        },
        phone: {
            status: false,
            message: 'Incorrect phone'
        }
    }); // Errors for input

    const responseError = ref('');   // Response Data

    onMounted( () => {
        formShown.value = true;
    } );

    /** Send questionary */
    const sendQuestionary = () => {
        hasErrors.value = false;
        inputsErrors.full_name.status = false;
        inputsErrors.phone.status = false;

        let words = formData.full_name.split(' ');
        if(words.length < 2) {
            inputsErrors.full_name.status = true;
            hasErrors.value = true;
        }

        /* if(formData.phone_number != props.phone){
            inputsErrors.phone.status = true;
            hasErrors.value = true;
        } */

        if( !formData.rules_accepted ){
            hasErrors.value = true;
        }

        if(hasErrors.value) return;

        axios.post('/questionary/update', formData)
        .then(function (response) {
            console.log(response.data);
            // If success response
            if( response.data.success != null ){
                window.open('/cabinet', "_self");
            } else {
                responseError.value = response.data;
            }
        }).catch(function (error) {
            console.log(error);
        });

    }

</script>

<template>
    <transition name="fade" mode="out-in">
        <form class="main-container auth" v-if="formShown" @submit.prevent="sendQuestionary">
            <h2 class="main-title">
                Реєстрація в сервісі
            </h2>

            <ul class="auth__ul">
                <li class="auth__li">
                    <input class="auth__input" type="text" placeholder="ПІБ" id="full_name" name="full_name" v-model="formData.full_name" required>
                </li>

                <li class="auth__li">
                    <input class="auth__input" type="text" placeholder="Номер телефону" id="phone" name="phone_number" v-model="formData.phone_number" readonly required>
                </li>

                <li class="auth__li">
                    <input class="auth__input" type="email" placeholder="Email" id="email" name="email" v-model="formData.email" required>
                </li>

                <li>
                    <div class="auth-accept">
                        <label class="container">
                            <input type="checkbox" name="rules" id="checkbox" v-model="formData.rules_accepted">
                            <span class="checkmark"></span>
                        </label>
                        <label for="checkbox">З <a href="/agreement" target="_blank">правилами</a> сервісу
                                погоджуюсь</label>
                    </div>
                </li>

                <li>
                    <button type="submit" class="btn btn-green-outline" :style="hasErrors ? {'margin-bottom': '20px'} : ''">Зареєструватись</button>
                    <transition name="fade">
                        <p class="error" v-if="hasErrors && inputsErrors.full_name.status">{{ inputsErrors.full_name.message }}</p>
                        <p class="error" v-else-if="hasErrors && !formData.rules_accepted">You need to be agreed with rules</p>
                        <p class="error" v-else-if="hasErrors && inputsErrors.phone.status">{{ inputsErrors.phone.message }}</p>
                    </transition>

                    <transition name="fade">
                        <p class="error" v-if="responseError.error != null">{{ responseError.error }}</p>
                    </transition>
                </li>
            </ul>
        </form>
    </transition>
</template>
